import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

import Header from '../components/Header';
import Footer from '../components/Footer';
import { CheckIcon } from '@heroicons/react/20/solid'
import company1light from '../images/company-1-white.webp';
import talent5 from '../images/optometry-1.webp';

const includedFeatures = [
    'Simple pricing plan',
    'US-based training program',
    'No hidden costs or fees',
    'Visiblity into staff productivity',
    'Same person every day',
    'Dedicated client support manager'
]

const faqs = [
    {
        question: "What is included in the hourly-based pricing plans?",
        answer: (
            <span>
                Our hourly-based pricing plans include simple monthly billing, visibility into staff productivity, and no hidden costs or fees.
            </span>
        ),
    },
    {
        question: "What kind of training do your team members undergo?",
        answer: (
            <span>
                Our team members go through a robust, US-based medical training program before joining your team, ensuring they are well-prepared to assist in healthcare settings.
            </span>
        ),
    },
    {
        question: "What flexibility do I have with your service?",
        answer: (
            <span>
                You can enjoy flexibility with our service, allowing you to scale or cancel as required without any hassle.
            </span>
        ),
    },
    {
        question: "What about security and compliance?",
        answer: (
            <span>
                Visit <a href="https://trust.janiehealth.com" target="_blank" rel="noopener noreferrer" className="text-teal-600 hover:underline">trust.janiehealth.com</a> to learn how we are committed to safeguarding the data of our customers and employees.
            </span>
        ),
    },
    {
        question: "How do I get started with your service?",
        answer: (
            <span>
                Getting started is easy! Simply contact our sales team or sign up directly on our website. We offer a free consultation to understand your needs and set up your account.
            </span>
        ),
    },
    {
        question: "Are there any onboarding fees?",
        answer: (
            <span>
                No, there are no onboarding fees. Our goal is to make it as easy as possible for you to start using our services without incurring additional costs.
            </span>
        ),
    },
];



export default function PricingPage() {

    return (
        <div className="bg-white mt-16">

            {/* HEADER */}
            <Header />

            {/* Pricing */}
            <div className="bg-white pt-48 xs:pt-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl sm:text-center">
                        <h2 className="text-5xl font-heading tracking-tight text-gray-900 sm:text-6xl font-semibold">Expert medical talent at a fraction of the cost</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Our team members have healthcare backgrounds and go through a robust, US-based medical training program before joining your team.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-3xl font-heading tracking-tight text-gray-900">Hourly-based pricing plans, billed monthly</h3>
                            <p className="mt-6 text-sm leading-7 text-gray-600">
                                Our specific pricing plans are based on medical background and US healthcare experience
                            </p>
                            <div className="mt-10 flex items-center gap-x-4">
                                <h4 className="flex-none text-md font-semibold leading-6 text-teal-900">What’s included</h4>
                                <div className="h-px flex-auto bg-gray-100" />
                            </div>
                            <ul
                                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                            >
                                {includedFeatures.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon className="h-6 w-6 flex-none text-teal-900" aria-hidden="true" />
                                        <span className="text-base">{feature}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                                <div className="mx-auto max-w-xs px-8">
                                    <p className="text-lg font-semibold text-gray-600">Starting at just</p>
                                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                        <span className="text-5xl font-bold tracking-tight text-gray-900">$11</span>
                                        <span className="text-md font-semibold leading-6 tracking-wide text-gray-600">per hour</span>
                                    </p>
                                    <a
                                        href="/contact"
                                        className="mt-10 block w-full rounded-md bg-gradient-to-r from-teal-700 to-teal-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                    >
                                        Get started
                                    </a>
                                    <p className="mt-6 text-md leading-5 text-gray-600">
                                        Enjoy flexiblity, so you can scale or cancel as required.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Testimonial section */}
            <div className="mx-auto mt-24 max-w-7xl sm:mt-32 sm:px-6 lg:px-8">
                <div className="relative overflow-hidden bg-gradient-to-r from-teal-700 to-teal-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
                    <img
                        className="absolute inset-0 h-full w-full object-cover saturate-0"
                        src={talent5}
                        alt=""
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-teal-900 to-teal-950/80" />

                    <div className="relative mx-auto max-w-2xl lg:mx-0">
                        <img className="h-10 pb-2 w-auto" src={company1light} alt="" />
                        <figure>
                            <blockquote className="mt-6 text-xl font-heading text-white sm:text-2xl sm:leading-8">
                                <p>
                                    "All of the billing candidates exceeded my expectations. We've been amazed by Janie so far and are excited for Diana to hit the ground running."
                                </p>
                            </blockquote>
                            <figcaption className="mt-6 text-base text-white">
                                <div className="font-semibold">Alexis</div>
                                <div className="mt-1">Fillmore Eye Clinic</div>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>

            {/* FAQ section */}
            <div className="mx-auto my-24 max-w-7xl px-6 sm:my-32 lg:px-8">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-heading leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <DisclosureButton className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </DisclosureButton>
                                        </dt>
                                        <DisclosurePanel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </DisclosurePanel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>

            {/* FOOTER */}
            <Footer />
        </div>
    )
}
