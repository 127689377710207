import Header from '../components/Header';
import Footer from '../components/Footer';

export default function PrivacyPolicyPage() {

    return (
        <div className="bg-white">

            {/* HEADER */}
            <Header />

            <div className="bg-white px-6 py-32 lg:px-8 mt-16">
                <div className="mx-auto max-w-3xl text-base leading-7">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-teal-900 sm:text-4xl">Privacy Policy</h1>
                    <p className="mt-6 text-md leading-8">
                        Effective Date: May 24, 2024
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>1. Information Collection</strong><br />
                        Health Sphere Innovations, LLC operates the JanieHealth.com website, which provides healthcare office staffing services. We collect personally identifiable information through forms on our website. This information is stored privately and securely and is not sold to third parties.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>2. Use of Information</strong><br />
                        The information we collect is used to facilitate the services provided on JanieHealth.com, including but not limited to, staffing solutions and service inquiries. We may also use this information for analytics and advertising purposes.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>3. Cookies and Tracking Technologies</strong><br />
                        We use cookies and similar tracking technologies to track the activity on our service and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. You have the option to accept or refuse these cookies and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>4. Security</strong><br />
                        We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>5. Links to Other Sites</strong><br />
                        Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>6. Changes to This Privacy Policy</strong><br />
                        We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>7. Partner Disclosures</strong><br />
                        We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the Microsoft Privacy Statement.
                    </p>

                    <p className="mt-6 text-md leading-8">
                        <strong>Contact Information</strong><br />
                        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at contact@janiehealth.com.
                    </p>
                </div>
            </div>



            {/* FOOTER */}
            <Footer />
        </div>
    )
}
