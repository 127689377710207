import Header from '../components/Header';
import Footer from '../components/Footer';
import { UserGroupIcon, WrenchScrewdriverIcon, PhoneArrowUpRightIcon, EyeIcon, EyeDropperIcon, HeartIcon, HandRaisedIcon, DevicePhoneMobileIcon, CheckIcon } from '@heroicons/react/24/outline'
import priscila from '../images/priscila.webp';
import elisa from '../images/elisa.webp';
import andres from '../images/andres.webp';
import cornel from '../images/cornel.webp';
import cornel_audio from '../audio/cornel-sample.mp3';
import priscila_audio from '../audio/priscila-sample.mp3';
import elisa_audio from '../audio/elisa-sample.mp3';
import ReactAudioPlayer from 'react-audio-player';
import hero from '../images/medical-11.webp';

const specialties = [
    {
        name: 'General Medical and Healthcare',
        description:
            'We serve clients across a variety of medical specialties including primary care, pediatrics, dermatology, urology, orthopedics, medical spas, and many others. Our Remote Healthcare Assistants (RHAs) can fill a variety of roles in any office setting.',
        icon: UserGroupIcon,
    },
    {
        name: 'Dental',
        description:
            'We serve general and pediatric dentists, orthodontists, endodontists, and oral surgeons. Have your remote healthcare staff manage all your insurance verification, scheduling, and patient recall for your practice.',
        icon: WrenchScrewdriverIcon,
    },
    {
        name: 'Contact Centers and Support Organizations',
        description:
            'We help DSOs, MSOs, and RCM agencies staff their contact centers and save ~50% on overhead costs. Our agents are experienced billers, schedulers, and insurance specialists.',
        icon: PhoneArrowUpRightIcon,
    },
    {
        name: 'Optometry',
        description:
            'Our clients include optometrists, ophthalmologists, optical shops, and retinal specialists. RHAs coordinate lens/frame deliveries, schedule appointments, and handle patient inquiries.',
        icon: EyeIcon,
    },
    {
        name: 'Pharmacy',
        description:
            'Janie supports retail pharmacies, compounding pharmacies, and independent pharmacies. RHAs assist with phone support, prescription management, RX refills, follow-ups, and scheduling for pharmacists.',
        icon: EyeDropperIcon,
    },
    {
        name: 'Veterinary',
        description:
            'We work with small animal clinics, large animal practices, and specialty veterinary services. RHAs handle insurance verification, patient queries, prescription refills, and scheduling.',
        icon: HeartIcon,
    },
    {
        name: 'Surgery Centers',
        description:
            'We partner with outpatient surgery centers and specialized surgical facilities. RHAs manage prior authorizations and claims follow-up, assist with scheduling, scribe for physicians and surgeons, and coordinate post-op follow-ups.',
        icon: HandRaisedIcon,
    },
    {
        name: 'Telehealth',
        description:
            'Our clients include telehealth providers across various medical fields. Our medically trained telehealth consultants provide initial consultations to potential patients, schedule appointments, and conduct remote patient monitoring.',
        icon: DevicePhoneMobileIcon,
    },
];

const roles = [
    {
        name: 'Insurance Coordinator',
        description: 'Prior authorization and insurance verification, credentialing support, claims follow-up, general admin tasks.',
        href: '#',
    },
    {
        name: 'Receptionist',
        description: 'Patient intake, appointment scheduling, handling calls and emails, recall services, admin tasks, documentation.',
        href: '#',
    },
    {
        name: 'Medical Scribe',
        description: 'Documenting patient visits, transcribing notes, managing EHR, assisting with medical documentation.',
        href: '#',
    },
    {
        name: 'Patient Coordinator',
        description: 'Scheduling appointments, managing patient records, coordinating follow-ups, supporting care plans.',
        href: '#',
    },
    {
        name: 'Billing / RCM Specialist',
        description: 'Medical billing and coding, claims processing, payment reconciliation, accounts receivable follow-up.',
        href: '#',
    },
    {
        name: 'Telehealth Consultant',
        description: 'Virtual consultations, telehealth setup, remote patient monitoring, providing virtual medical support.',
        href: '#',
    },
]

const people = [
    {
        name: 'Cornel',
        role: 'Medical Scribe',
        imageUrl: cornel,
        audioUrl: cornel_audio,
        bio: 'Cornel is a certified Doctor of Medicine from the Philippines with deep experience as a medical scribe. He has worked with a variety of medical practices in the US, including psychiatry, neurology, and dermatology.',
    },
    {
        name: 'Priscila',
        role: 'Patient Care Coordinator',
        imageUrl: priscila,
        audioUrl: priscila_audio,
        bio: 'Priscila is a trained nurse from Brazil with a certificate in US medical billing and coding. Her most recent role was scheduling appointments and coordinating insurance as a remote receptionist for a dental office in Texas.',
    },
    {
        name: 'Elisa',
        role: 'Telehealth Consultant',
        imageUrl: elisa,
        audioUrl: elisa_audio,
        bio: 'Elisa, a board-certified Medical Doctor from Honduras, has a strong background in providing direct care and remote patient monitoring. She has worked in telehealth support with various US practices across California and Arizona.',
    },
    {
        name: 'Will',
        role: 'Billing and Insurance Specialist',
        imageUrl: andres,
        audioUrl: '',
        bio: 'Will is a certified medical coder and MD from the Philippines with over 7 years of experience in US medical billing. He specializes in end-to-end billing and revenue cycle management, particularly in pain management and orthopedics.',
    },
]

const benefits = [
    {
        name: 'Starting at $10 an hour',
        description: 'Our team members cost between $10 to $12 an hour depending on their experience. Save thousands of dollars by choosing a Remote Healthcare Assistant.',
    },
    {
        name: 'Part of your team',
        description: 'With average retention at 93%, our Remote Healthcare Assistants will integrate with your office and become long-term members of your staff. You\'ll work with the same person, every day.'
    },
    {
        name: 'HIPAA certified',
        description: 'All of our Remote Healthcare Assistants are HIPAA-certified and fully compliant. Our systems and processes are designed to protect patient data and privacy.',
    },
    {
        name: 'Use any EMR / EHR',
        description: 'Your Remote Healthcare Assistant will utilize the same tools, systems, and processes currently being used in your practice. They\'ll be onboarded to your office systems just like any other employee.',
    },
    {
        name: 'Medically trained professionals',
        description: 'All of our team members are licensed medical professionals with formal education and certifications in medicine, dentistry, nursing, physical therapy, medical coding, and more.'
    },
    {
        name: 'Grow your team quickly',
        description: 'We can have new staff members in your office in less than a week. Our goal is to help you scale (or downsize) your team as quickly as possible, depending on your needs.'
    },
    {
        name: 'No long-term commitment',
        description: 'Enjoy hassle-free monthly billing with no setup fees or long-term contracts. Our flexible billing process is tailored to meet your unique needs, allowing you the freedom to cancel at any time.',
    },
    {
        name: 'Best of the best',
        description: 'Our global recruiting team works tirelessly to find, screen, and train the best Remote Healthcare Assistants in the world. We prioritize integrity, work ethic, and kindness.',
    },
]


export default function StaffingPage() {

    return (
        <div className="bg-white">

            {/* HEADER */}
            <Header />

            {/* HERO */}
            <div className="relative isolate overflow-hidden bg-gradient-to-b from-teal-800 to-teal-900 py-32 sm:py-56 mt-36">
                <div className="absolute inset-0 z-0">
                    <img
                        src={hero}
                        alt="Janie Health"
                        className="w-full h-full object-cover"
                        draggable="false"
                    />
                </div>
                <div className="absolute inset-0 z-10 bg-gradient-to-b from-teal-800 to-teal-900 opacity-90"></div>
                <div className="relative z-20 mx-auto max-w-4xl text-center">
                    <h2 className="text-4xl font-heading font-semibold tracking-tight text-white sm:text-6xl">Our staffing services</h2>
                    <p className="mt-6 text-xl leading-8 text-white">
                        Janie is supported by a team of practicing physicians to ensure that our Remote Healthcare Assistants are fully prepared to meet the needs of today’s physicians and practice managers.
                    </p>
                    <div className="mt-20">
                        <a
                            href="/contact"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-teal-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                        >
                            Get started today
                        </a>
                    </div>
                </div>
            </div>


            {/* specialties */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto px-6 lg:px-8">
                    <div className="mx-auto max-w-3xl lg:text-center">
                        <p className="mt-2 text-4xl font-heading tracking-tight text-gray-900 md:text-5xl">
                            Healthcare organizations we serve
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Our Remote Healthcare Assistants and phone agents work across a variety of medical sub-specialities and organizations.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-6xl sm:mt-20 lg:mt-24 lg:max-w-5xl">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            {specialties.map((feature) => (
                                <div key={feature.name} className="relative pl-16">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient-to-b from-teal-800 to-teal-900">
                                            <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </div>
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            {/* ROLES */}
            <div className="bg-gradient-to-b from-teal-800 to-teal-900 py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-heading tracking-tight text-white sm:text-4xl">Roles we staff</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Our experienced healthcare staff can optimize your office operations, improve patient experience, and reduce overhead costs.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                            {roles.map((feature) => (
                                <div key={feature.name} className="flex flex-col">
                                    <dt className="text-lg font-semibold leading-7 text-white">
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                        <p className="flex-auto">{feature.description}</p>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            {/* PEOPLE */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl sm:text-center">
                        <h2 className="text-3xl font-heading tracking-tight text-gray-900 sm:text-4xl">Meet some of our staff</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            We are a global team of passionate medical professionals with expertise in medical billing, insurance coordination, credentialing, scribing, and patient scheduling.
                        </p>
                    </div>
                    <ul
                        className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
                    >
                        {people.map((person) => (
                            <li key={person.name} className="flex flex-col gap-6 xl:flex-row">
                                <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src={person.imageUrl} alt="" draggable="false" />
                                <div className="flex-auto">
                                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                                    <p className="text-base leading-7 text-gray-600">{person.role}</p>
                                    <p className="mt-6 mb-4 text-base leading-7 text-gray-600">{person.bio}</p>
                                    {person.audioUrl !== '' ? <ReactAudioPlayer src={person.audioUrl} controls /> : <></>}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* CTA */}
            <div className="bg-gradient-to-b from-teal-800 to-teal-900 mb-24">
                <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
                    <h2 className="text-3xl font-heading tracking-tight text-white sm:text-4xl">
                        Ready to dive in?
                        <br />
                        Schedule a call today.
                    </h2>
                    <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                        <a
                            href="/contact"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-teal-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                        >
                            Get started
                        </a>
                        <a href="/contact" className="text-sm font-semibold leading-6 text-white">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
            </div>

            {/* Benefits */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">

                        <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                            {benefits.map((feature) => (
                                <div key={feature.name} className="relative pl-9">
                                    <dt className="font-semibold text-gray-900 text-lg">
                                        <CheckIcon className="absolute left-0 top-1 h-6 w-6 text-teal-500" aria-hidden="true" />
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                        <div>
                            <h2 className="text-base sm:text-right font-semibold leading-7 text-teal-900">Low cost, highly beneficial</h2>
                            <p className="mt-2 text-3xl sm:text-right font-heading tracking-tight text-gray-900 sm:text-4xl">Our client benefits</p>
                            <p className="mt-6 text-base sm:text-right leading-7 text-gray-600">
                                Solve your practice office staffing problems with our fully managed approach to staffing.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA */}
            <div className="bg-gradient-to-b from-teal-800 to-teal-900 mb-24">
                <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
                    <h2 className="text-3xl font-heading tracking-tight text-white sm:text-4xl">
                        No long term commitments.
                        <br />
                        Schedule a call today.
                    </h2>
                    <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                        <a
                            href="/contact"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-teal-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                        >
                            Get started
                        </a>
                        <a href="/services" className="text-sm font-semibold leading-6 text-white">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <Footer />
        </div>
    )
}
